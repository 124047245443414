<template>

  <div>

    <b-button
      v-if="hits.length"
      class="bg-gradient-primary mr-1"
      variant="primary"
      @click="playAll"
    >
      <feather-icon
        size="13"
        icon="PlayIcon"
        fill="#FFF"
        cursor="pointer"
      />
      Ouvir
    </b-button>

    <b-button
      v-if="hits.length && filtrar >= 0"
      v-ripple.400="'rgba(113, 102, 240, 0.15)'"
      v-b-modal.modal-filtro
      variant="dark"
    >
      <feather-icon
        size="13"
        icon="FilterIcon"
        cursor="pointer"
      />
      Filtros
    </b-button>

    <b-button
      v-if="filtrar > 0"
      v-ripple.400="'rgba(113, 102, 240, 0.15)'"
      variant="outline-dark"
      class="ml-1"
      @click="limparFiltro()"
    >
      <feather-icon
        size="13"
        icon="FilterIcon"
        cursor="pointer"
      />
      Limpar
    </b-button>

    <b-modal
      id="modal-filtro"
      ok-only
      ok-title="Aplicar"
      centered
      no-close-on-backdrop
      size="lg"
      title="Filtrar Pesquisa"
      @ok="filtrarPesquisa"
    >

      <validation-observer
        #default="{invalid}"
        ref="temaForm"
      >
        <!-- Form -->
        <b-form
          ref="temaForms"
          class="p-2"
          @submit.prevent="hitTemaCadastrar"
        >

          <b-row>

            <b-col
              xl="6"
              cols="12"
            >

              <!-- Estilos -->
              <b-form-group
                label="Estilo"
                label-for="hitEstilo"
              >
                <validation-provider
                  #default="{ errors }"
                  name="hitEstilo"
                >
                  <v-select
                    v-model="hitEstilos"
                    multiple
                    label="nome"
                    :options="estilos"
                    :reduce="(option) => option.id"
                  />
                </validation-provider>
              </b-form-group>
              <!-- Estilos -->

            </b-col>

            <b-col
              xl="6"
              cols="12"
              >

              <!-- Temas -->
              <b-form-group
                label="Tema"
                label-for="hitTema"
              >
                <validation-provider
                  #default="{ errors }"
                  name="hitTema"
                >
                  <v-select
                    v-model="hitTemas"
                    multiple
                    label="nome"
                    :options="temas"
                    :reduce="(option) => option.id"
                  />
                </validation-provider>
              </b-form-group>
              <!-- Temas -->

            </b-col>

          </b-row>

          <b-row>

            <b-col
              xl="6"
              cols="12"
            >

              <b-form-group
                label="Valor de Liberação"
                label-for="hitCompositores"
              >
                <validation-provider
                  #default="{ errors }"
                  name="hitCompositores"
                >

                  <vue-slider
                    v-model="hitValorLiberacao"
                    :min="0"
                    :max="100000"
                    :tooltip="'none'"
                    class="mb-2"
                    @change="alterarValorLiberacao"
                  />

                  <div class="d-flex">
                    <b-form-group>
                      <money
                        v-model="filtroLiberacaoMin"
                        v-bind="money"
                        class="form-control lg"
                        @input="alterarValorLiberacaoInput"
                      />
                    </b-form-group>
                    <b-form-group class="ml-2">
                      <money
                        v-model="filtroLiberacaoMax"
                        v-bind="money"
                        class="form-control lg"
                        @input="alterarValorLiberacaoInput"
                      />
                    </b-form-group>
                  </div>

                </validation-provider>

              </b-form-group>

            </b-col>

            <b-col
              xl="6"
              cols="12"
            >

              <b-form-group
                label="Valor de Exclusividade"
                label-for="hitCompositores"
              >
                <validation-provider
                  #default="{ errors }"
                  name="hitCompositores"
                >

                  <vue-slider
                    v-model="hitValorExclusividade"
                    :min="0"
                    :max="100000"
                    :tooltip="'none'"
                    class="mb-2"
                    @change="alterarValorExclusividade"
                  />

                  <div class="d-flex">
                    <b-form-group>
                      <money
                        v-model="filtroExclusividadeMin"
                        v-bind="money"
                        class="form-control lg"
                        @input="alterarValorExclusividadeInput"
                      />
                    </b-form-group>

                    <b-form-group class="ml-2">
                      <money
                        v-model="filtroExclusividadeMax"
                        v-bind="money"
                        class="form-control lg"
                        @input="alterarValorExclusividadeInput"
                      />
                    </b-form-group>
                  </div>

                </validation-provider>

              </b-form-group>

            </b-col>

          </b-row>

        </b-form>
      </validation-observer>

    </b-modal>

    <section
      id="knowledge-base-content"
      ref="musicList"
      class="divHits pt-2 pb-5"
    >

      <div 
        v-if="!hits.length && filtrar == 0 && !carregamentoApi"
      >

          <b-col
            cols="12"
            style="padding: 0px;"
          >
            <b-card
              :no-body="true"
              class="text-left p-3"
              style="overflow: hidden;"
            >
              
              <b-card-body>

                <div style="position: absolute; top: 0; right: 0;z-index:0;">
                  <img src="@/assets/images/bg/bg.png" alt="Ícone Favoritos" />
                </div>

                <div style="position: relative;z-index:2;">
                  <b-card-title class="text-white">
                    <h1 class="content-header-title">Bem-vindo aos seus <b>hits</b></h1>
                  </b-card-title>
                  <b-card-text class="my-2">
                    <h6>
                      <b>Aqui você encontrará todos os 
                      seus hits.</b>
                      <br>No momento você ainda não possui nenhum hit ativo, dê o seu primeiro passo para o sucesso.        
                    </h6>
                  </b-card-text>

                  <b-button
                    class="bg-gradient-primary mt-1"
                    variant="primary"
                    :to="{ name: 'hit-cadastrar'}"
                  >
                    Enviar Hit
                  </b-button>
                </div>

              </b-card-body>

            </b-card>
          </b-col>

      </div>

      <b-card
        v-for="hit in hits"
        :key="hit.id"
        :ref="checkActiveMusic(hit.id) ? 'activeMusic' : ''"
        :class="checkActiveMusic(hit.id) ? 'active-color' : ''"
        class="card-transaction hitPlayCard"
        no-body
      >

        <b-card-body>
          <div
            class="transaction-item"
          >
            <b-media no-body>
              <b-media-aside>


                <div
                  v-if="checkActiveMusic(hit.id) && getPlayingStatus"
                  class="boxContainer"
                >
                  <div class="box box1"></div>
                  <div class="box box2"></div>
                  <div class="box box3"></div>
                  <div class="box box4"></div>
                  <div class="box box5"></div>
                </div>

                <b-icon
                  v-else
                  font-size="30"
                  icon="play-circle"
                  cursor="pointer"
                  class="btPlay"
                  @click="selectMusic(hit);updateMusicListScroll($event);"
                />

              </b-media-aside>
              <b-media-body>
                <h6 class="transaction-title">
                  <span :class="checkActiveMusic(hit.id) ? 'active-text-color' : ''">{{ hit.nomeComposicao }}</span>
                </h6>
                <b-badge
                  pill
                  :variant="`light-${statusAprovacaoVariant(hit.statusAprovacaoHit)}`"
                  class="text-capitalize badgeStatus"
                >
                  {{ statusAprovacaoLabel(hit.statusAprovacaoHit) }}
                </b-badge>
                <div class="audio-player" />
              </b-media-body>
            </b-media>

          </div>
        </b-card-body>

      </b-card>

      <infinite-loading
        ref="infiniteLoading"
        :infinite-scroll-disabled="carregamentoApi"
        @infinite="hitsLista"
        :identifier="filtrar"
        no-results="noResults"
      >

        <span slot="spinner">
          <img
            src="@/assets/images/logo/logo-loading.gif"
            width="40px"
          >
          <p>Carregando mais hits...</p>
        </span>
        <span slot="no-more"></span>
        <span slot="loading">Carregando mais hits...</span>

        <div slot="no-results">

          <div
            v-if="filtrar > 0"
          >
            <b-badge
              variant="light-danger"
            >
              Sem resultados
            </b-badge>
          </div>

        </div>

      </infinite-loading>


    </section>

    <!-- <HitPlayer class="footer fixed-bottom bg-dark" /> -->

  </div>
</template>

<script>
import {
  BRow, BCol, BModal, BCard, BCardTitle, BCardHeader, BCardBody, BForm, BFormGroup, BInputGroup, BFormInput, BCardText, BInputGroupPrepend, IconsPlugin, BBadge, BButton, BMediaBody, BMedia, BMediaAside, BAvatar, BDropdown, BDropdownItem,
} from 'bootstrap-vue'

import { ValidationProvider, ValidationObserver } from 'vee-validate'

import vSelect from 'vue-select'

import useJwt from '@/auth/jwt/useJwt'

import VueAudio from 'vue-audio'

import AudioPlayer from '@liripeng/vue-audio-player'

// CARREGANDO DE PÁGINA PARA API
import VueElementLoading from 'vue-element-loading'

import axios from 'axios'

import aws4 from 'aws4'

import globalMixins from '@/mixins/globalMixins'
import musicsMixins from '@/mixins/musicsMixins'

import VueSlider from 'vue-slider-component'

import { Money } from 'v-money'

import Ripple from 'vue-ripple-directive'

import InfiniteLoading from 'vue-infinite-loading'
import HitPlayer from './HitPlayer.vue'
import HitPlay from './HitPlay'

export default {
  name: 'AllMusicsPlayList',
  components: {
    BRow,
    BCol,
    BModal,
    BCard,
    BCardTitle,
    BCardHeader,
    BCardBody,
    BCardText,
    BForm,
    BFormGroup,
    BInputGroup,
    BInputGroupPrepend,
    IconsPlugin,
    BFormInput,
    BBadge,
    BButton,
    BMediaBody,
    BMedia,
    BMediaAside,
    BAvatar,
    BDropdown,
    BDropdownItem,
    AudioPlayer,
    VueElementLoading,
    aws4,
    HitPlayer,

    // Form Validation
    ValidationProvider,
    ValidationObserver,

    vSelect,
    VueSlider,
    Money,
    InfiniteLoading,
  },
  directives: {
    Ripple,
  },
  mixins: [globalMixins, musicsMixins],
  data() {
    return {

      hits: [],
      carregamentoApi: false,
      audioList: ['http://www.ytmp3.cn/down/76865.mp3'],
      tocando: false,
      selected_options: [],
      activePlayList: 'all',

      // FILTRO

      hitValorLiberacao: [0, 100000],
      hitValorExclusividade: [0, 100000],

      // ESTILOS

      estilos: [],
      hitEstilos: [], // Estilos selecionados

      // TEMAS

      temas: [],
      hitTemas: [], // Temas selecionados

      filtroLiberacaoMin: 0,
      filtroLiberacaoMax: 100000,
      filtroExclusividadeMin: 0,
      filtroExclusividadeMax: 100000,

      filtrar: 0,

      page: 0,
      size: 8,
      sort: 'ASC',

      money: {
        decimal: ',',
        thousands: '.',
        prefix: 'R$ ',
        precision: 2,
        masked: false,
      },

      // FILTRO

      

    }
  },
  created() {
    this.hitEstilosLista()
    this.hitTemasLista()
  },
  mounted() {
  },
  beforeDestroy() {
  },
  methods: {

    // ALTERAR VALOR LIBERAÇÃO

    alterarValorLiberacao(val) {
      const valorLiberacao = val

      this.filtroLiberacaoMin = valorLiberacao[0]
      this.filtroLiberacaoMax = valorLiberacao[1]
    },

    alterarValorLiberacaoInput() {
      const valorLiberacao = []

      valorLiberacao.push(Number(this.filtroLiberacaoMin))
      valorLiberacao.push(Number(this.filtroLiberacaoMax))

      this.hitValorLiberacao = valorLiberacao
    },

    // ALTERAR VALOR LIBERAÇÃO

    // ALTERAR VALOR LIBERAÇÃO

    alterarValorExclusividade(val) {
      const valorExclusividade = val

      this.filtroExclusividadeMin = valorExclusividade[0]
      this.filtroExclusividadeMax = valorExclusividade[1]
    },

    alterarValorExclusividadeInput() {
      const valorExclusividade = []

      valorExclusividade.push(Number(this.filtroExclusividadeMin))
      valorExclusividade.push(Number(this.filtroExclusividadeMax))

      this.hitValorExclusividade = valorExclusividade
    },

    // ALTERAR VALOR LIBERAÇÃO

    // FORMATAR MOEDA

    formatarMoeda(val) {
      return val.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })
    },

    // FORMATAR MOEDA

    // ESTILOS

    hitEstilosLista() {
      useJwt.hitEstilosLista({
        page: 0,
        size: 9999,
        sort: 'ASC',
      })
        .then(response => {

          response.data.content.forEach(estilo => {
            this.estilos.push({ nome: estilo.nome, id: estilo.id })
          })

        })
        .catch(error => {
          console.log(error)
        })
    },

    // TEMAS

    hitTemasLista() {
      useJwt.hitTemasLista({
        page: 0,
        size: 9999,
        sort: 'ASC',
      })
        .then(response => {

          response.data.content.forEach(tema => {
            this.temas.push({ nome: tema.nome, id: tema.id })
          })
        })
        .catch(error => {
          console.log(error)
        })
    },

    // FILTRAR PESQUISA
    filtrarPesquisa() {
      this.page = 0
      this.hits = []
      this.filtrar += 1
    },
    // FILTRAR PESQUISA

    limparFiltro() {

      this.hits = []

      this.hitEstilos = []
      this.hitTemas = []

      this.filtroLiberacaoMin = 0
      this.filtroLiberacaoMax = 100000
      this.filtroExclusividadeMin = 0
      this.filtroExclusividadeMax = 100000

      this.page = 0

      this.filtrar = 0

    },

    // STATUS

    statusAprovacaoVariant(status) {
      if (status === 'EM_ANALISE') return 'warning'
      if (status === 'APROVADO') return 'success'
      if (status === 'REPROVADO_GERAL') return 'danger'
      if (status === 'REPROVADO_INFORMAÇÕES') return 'danger'
      if (status === 'REPROVADO_GUIA') return 'danger'
      if (status === 'PENDENTE_PAGAMENTO') return 'warning'
      if (status === 'AGUARDANDO_GUIA') return 'warning'
      return 'warning'
    },

    statusAprovacaoLabel(status) {
      if (status === 'EM_ANALISE') return 'Em análise'
      if (status === 'APROVADO') return 'Aprovado'
      if (status === 'REPROVADO_GERAL') return 'Reprovado'
      if (status === 'REPROVADO_INFORMACOES') return 'Reprovado'
      if (status === 'REPROVADO_GUIA') return 'Reprovado'
      if (status === 'PENDENTE_PAGAMENTO') return 'Pendente Pagamento'
      if (status === 'AGUARDANDO_GUIA') return 'Aguardando Guia'
      return 'Em análise'
    },

    // STATUS

    updatePlayList(playList) {
      this.activePlayList = playList
      this.$store.commit('updatePlayList', playList)
    },
    checkActivePlaylist(playlist) {
      return playlist == this.activePlayList
    },

    async hitsLista(infiniteLoading) {
      this.carregamentoApi = true


      useJwt.hitCompositorLista({
        liberacaoMin: this.filtroLiberacaoMin,
        liberacaoMax: this.filtroLiberacaoMax,
        exclusividadeMin: this.filtroExclusividadeMin,
        exclusividadeMax: this.filtroExclusividadeMax,
        listaTemas: this.hitTemas,
        listaEstilos: this.hitEstilos,
        page: this.page,
        size: this.size,
        sort: this.sort,
      })
        .then(response => {

          if (response.data.content.length) {
            this.page += 1
            this.hits.push(...response.data.content)
            infiniteLoading.loaded()
          } else {
            infiniteLoading.complete()
          }

        })
        .catch(error => {

          infiniteLoading.complete()

        })
        .finally(() => {
          this.carregamentoApi = false
        })
    },

    handleScroll() {

      // this.hitsLista();

    },

  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-knowledge-base.scss';
@import '@core/scss/mercado-hit.scss';

.mh-play {
  display: block;
  width: 40px;
  height: 40px;
}

@media only screen and (max-width: 767px) {
    .transaction-title {
        width: 250px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        font-size: 12px;
    }
}

.transaction-title {
  text-transform: lowercase;
}

.transaction-title::first-letter {
    text-transform: capitalize;
}

</style>
